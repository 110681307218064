import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="blue">Kannan Ravi </span>
            from <span className="blue"> Chennai, India.</span>
            <br />I am a 23-year-old recent <span className="blue">Pondicherry University Graduate </span> with a <span className="blue">Bachelor of Technology degree.</span> 
            <br />A keen interest of mine is collaborating and developing digital solutions that <span className="blue"> solve real-world problems. </span> 
            <br />Currently, I'm working on a few <span className="blue"> Machine learning & Development projects and new skills. </span>  Apart from coding, some other activities that <span className="blue"> I love to do! </span> 
          </p>
          <ul>
            <li className="about-activity" >
              <ImPointRight /> <span className="blue">Blo</span>gging <span className="blue"></span>
            </li>
            <li className="about-activity">
              <ImPointRight /> <span className="blue">Pla</span>ying Football
            </li>
            <li className="about-activity">
              <ImPointRight /> <span className="blue">Web </span>Surfing
            </li>
          </ul>
          <p style={{ color: "#167ced" }}>
            "Ups And Downs In Life Are Very Important To Keep Us Going!"{" "}
          </p>
          <footer className="blockquote-footer" ><span className="blue">Rat</span>an Tata</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
