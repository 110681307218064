import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import BrickBreakerGame from "../../Assets/Projects/Brickbreaker.png";
import VolumeControlHandGestures from "../../Assets/Projects/VolumeControlHandGesture.png";
import MovieRecommendation from "../../Assets/Projects/MovieRecommendation.png";
import Facemask from "../../Assets/Projects/Facemask.png";
import HandwrittenDigit from "../../Assets/Projects/HandwrittenDigit.png";
import StockPrediction from "../../Assets/Projects/StockPrediction.png";
import Dogsandcat from "../../Assets/Projects/Dogsandcat.png";
import Automate from "../../Assets/Projects/Automate.png";




function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={BrickBreakerGame}
              isBlog={false}
              title="BrickBreakerGame"
              description="Designed and implemented interactive graphical user interface for the game. Utilized Java Swing components for the layout and organization of game elements. Implemented the game logic, including ball movement, paddle control, and collision detection. Integrated scoring system and level progression functionality."
              ghLink="https://github.com/Kannxnravi/Brick-Breaker-Game"
              // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={VolumeControlHandGestures}
              isBlog={false}
              title="VolumeControlHandGestures"
              description="We are trying to process a video so that we can control volume of a device with help of its camera using our palm or precisely our thumb and index fingers."
              ghLink="https://github.com/Kannxnravi/Volume-Control-Using-Hand-Gestures"
              // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MovieRecommendation}
              isBlog={false}
              title="Movie Recommendation"
              description="A movie recommendation system, or a movie recommender system, is an ML-based approach to filtering or predicting the users' film preferences based on their past choices and behavior. It’s an advanced filtration mechanism that predicts the possible movie choices of the concerned user."
              ghLink="https://github.com/Kannxnravi/Movie-Recommendation.git"
              demoLink="https://medium.com/@kannanraviak/movie-recommendation-608cc49f1b06"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Facemask}
              isBlog={false}
              title="Face Mask Detection"
              description="Masks play a crucial role in protecting the health of individuals against respiratory diseases, as is one of the few precautions available for COVID-19 in the absence of immunization. With this dataset, it is possible to create a model to detect people wearing masks, not wearing them, or wearing masks improperly."
              ghLink="https://github.com/Kannxnravi/Face-Mask-Detection.git"
              demoLink="https://medium.com/@kannanraviak/face-mask-detection-6a5e375e8196"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={HandwrittenDigit}
              isBlog={false}
              title="Handwritten Digit Recognition "
              description="Handwritten digit recognition can be performed using the Convolutional neural network from Machine Learning. Using the MNIST (Modified National Institute of Standards and Technologies) database and compiling with the CNN gives the basic structure of my project development"
              ghLink="https://github.com/Kannxnravi/Handwritten-Digits-Recognition"     
              demoLink="https://medium.com/@kannanraviak/how-to-develop-a-convolutional-neural-network-from-scratch-for-mnist-handwritten-digit-fd16286d1bf3"        
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={StockPrediction}
              isBlog={false}
              title="Stock Prediction"
              description="Utilized an attention-based LSTM neural network to predict the Google stock price.Stock Price Prediction using machine learning helps you discover the future value of company stock and other financial assets traded on an exchange. The entire idea of predicting stock prices is to gain significant profits. Predicting how the stock market will perform is a hard task to do."
              ghLink="https://github.com/Kannxnravi/Stock-Price-Prediction"
              demoLink="https://medium.com/@kannanraviak/google-stock-price-prediction-using-lstm-463e5d13b251"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Dogsandcat}
              isBlog={false}
              title="Dogs and Cats"
              description="Develop a Deep Convolutional Neural Network Step-by-Step to Classify Photographs of Dogs and Cats.Convolutional Neural Network (CNN) is an algorithm taking an image as input then assigning weights and biases to all the aspects of an image and thus differentiates one from the other. Neural networks can be trained by using batches of images, each of them having a label to identify the real nature of the image."
              ghLink="https://github.com/Kannxnravi/Image-Based-Recognition"
              demoLink="https://medium.com/@kannanraviak/image-based-recognition-dog-and-cat-dc6dd9f50fab"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Automate}
              isBlog={false}
              title="Automate detection of different emotions from textual comments and feedback"
              description="To develop a deep learning algorithm to detect different types of sentiment contained in a collection of English Sentences or a large paragraph, whereas Emotion Analysis aims to detect and recognize types of feelings through the expression of texts, such as anger, disgust, fear, happiness, sadness, and surprise."
              ghLink="https://github.com/Kannxnravi/Automate-detection-of-different-emotions-from-textual-comments-and-feedback"
              demoLink="https://medium.com/@kannanraviak/automate-detection-of-different-emotions-from-textual-comments-and-feedback-f124f0f35b71"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
